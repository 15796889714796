<template>
  <div class="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-4xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
      <!-- En-tête -->
      <div class="bg-gradient-to-r bg-components py-8 px-6">
        <h1 class="text-3xl font-bold text-white text-center">
          Politique de Confidentialité
        </h1>
      </div>

      <div class="p-8 space-y-8">
        <!-- Types de données collectées -->
        <section class="hover:bg-gray-50 rounded-lg p-6 transition duration-300">
          <div class="flex items-start mb-4">
            <div class="p-2 bg-background rounded-lg mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-components"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-800 mb-3">
                Types de données collectées
              </h2>
              <p class="text-gray-600 leading-relaxed">
                Nous collectons les données suivantes : 
                <span class="block mt-2 ml-4">
                  • Nom et prénom<br>
                  • Adresse e-mail<br>
                  • Message
                </span>
                <span class="block mt-2">
                  Ces informations sont collectées via le formulaire de contact pour vous permettre de nous joindre.
                </span>
              </p>
            </div>
          </div>
        </section>

        <!-- Finalité de la collecte -->
        <section class="hover:bg-gray-50 rounded-lg p-6 transition duration-300">
          <div class="flex items-start mb-4">
            <div class="p-2 bg-background rounded-lg mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-components"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-800 mb-3">
                Finalité de la collecte
              </h2>
              <p class="text-gray-600 leading-relaxed">
                Les données collectées sont utilisées pour répondre à vos demandes et ne seront pas partagées avec des tiers sans votre consentement explicite.
              </p>
            </div>
          </div>
        </section>

        <!-- Durée de conservation -->
        <section class="hover:bg-gray-50 rounded-lg p-6 transition duration-300">
          <div class="flex items-start mb-4">
            <div class="p-2 bg-background rounded-lg mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-components"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-800 mb-3">
                Durée de conservation
              </h2>
              <p class="text-gray-600 leading-relaxed">
                Vos données personnelles sont conservées pendant une durée maximale de 1 an à compter de votre dernier contact avec nous.
              </p>
            </div>
          </div>
        </section>

        <!-- Droits des utilisateurs -->
        <section class="hover:bg-gray-50 rounded-lg p-6 transition duration-300">
          <div class="flex items-start mb-4">
            <div class="p-2 bg-background rounded-lg mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-components"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-800 mb-3">
                Droits des utilisateurs
              </h2>
              <p class="text-gray-600 leading-relaxed">
                Conformément au RGPD, vous disposez des droits suivants :
                <span class="block mt-2 ml-4">
                  • Droit d'accès à vos données<br>
                  • Droit de rectification<br>
                  • Droit de suppression<br>
                  • Droit de retrait du consentement
                </span>
                <span class="block mt-2">
                  Pour exercer ces droits, contactez-nous à <span class="text-components">cyril.dohin@gmail.com</span>
                </span>
              </p>
            </div>
          </div>
        </section>

        <!-- Protection des données -->
        <section class="hover:bg-gray-50 rounded-lg p-6 transition duration-300">
          <div class="flex items-start mb-4">
            <div class="p-2 bg-background rounded-lg mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-components"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                />
              </svg>
            </div>
            <div class="flex-1">
              <h2 class="text-xl font-semibold text-gray-800 mb-3">
                Protection des données
              </h2>
              <p class="text-gray-600 leading-relaxed">
                Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non autorisé, modification, divulgation ou destruction.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>